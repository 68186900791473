import { useEffect, useState } from 'react';

import { log } from '@/components/shared/element/debug-bar';

export interface IFlutter {
  my_id: string;
}

export const LOCAL_STORAGE_FLUTTER_KEY = 'local-storage-flutter-key';

export const useFlutterWebview = () => {
  const [data, setData] = useState<IFlutter>(() => {
    const defaultValue: IFlutter = { my_id: '' };
    try {
      const value = window.localStorage.getItem(LOCAL_STORAGE_FLUTTER_KEY);

      if (value) {
        return JSON.parse(value) as IFlutter;
      } else {
        window.localStorage.setItem(
          LOCAL_STORAGE_FLUTTER_KEY,
          JSON.stringify(defaultValue)
        );
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      log(event.data);
      // NOTE: need to send my_id fields
      if (!event.data.my_id) return;
      try {
        window.localStorage.setItem(
          LOCAL_STORAGE_FLUTTER_KEY,
          JSON.stringify(event.data)
        );
        setData(event.data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return data;
};
